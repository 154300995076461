import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../common/hooks';
import {
  DialogueModuleRequest,
  initialDialogueModules,
  TemporaryBotRequest,
} from '../sample_data';

import {
  Button,
  COLOR_NEUTRAL_30,
  ContentContainer,
  H2,
  MainContainer,
} from '../../../App.style';
import { selectUserSettings } from '../../settings/userSettings/api/userSettingsSlice';
import {
  selectStrategiesList,
  selectStrategyId,
} from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { styled } from 'styled-components';
import '@xyflow/react/dist/style.css';
import Sidebar from '../../sidebar/Sidebar';
import { SettingsIcon } from '../../../../resources/icons-new/SettingsIcon';

import { ArrowLeftIcon } from '../../../../resources/icons-new/ArrowLeftIcon';
import SuccessPopup from '../../../../common/popups/SuccessPopup';
import GraphDisplay from './GraphDisplay';

const GraphPanel = () => {
  const strategy = useAppSelector(selectStrategyId);
  const [successPopupVisible, setSuccessPopupVisible] =
    useState<boolean>(false);

  const { botId } = useParams();

  const navigate = useNavigate();

  const [bot, setBot] = useState<TemporaryBotRequest>({
    name: 'test',
    strategy: 'elo',
    status: 'TESTING',
    description: 'Elo',
    dialogue_modules: initialDialogueModules,
  });
  const [modules, setModules] = useState<DialogueModuleRequest[]>(
    initialDialogueModules,
  );

  const [selectedWorkspaceName, setSelectedWorkspaceName] =
    useState<string>('');
  const apiWorkspacesList = useAppSelector(selectStrategiesList);
  const apiUserSettings = useAppSelector(selectUserSettings);

  useEffect(() => {
    if (apiUserSettings.value && apiWorkspacesList) {
      const selectedWorkspace = apiWorkspacesList.find(
        (item) => item.id === strategy,
      );

      if (selectedWorkspace)
        setSelectedWorkspaceName(selectedWorkspace.name.split('-')[0]);
    }
  }, []);

  useEffect(() => {
    //api call for bot details
    // if (botId) {
    //   dispatch(getBotDetailedAsyncThunk(botId))
    //     .then(unwrapResult)
    //     .then((res) => {
    //       setBot(res);
    //       if (res.dialogue_modules) {
    //         setModules(res.dialogue_modules);
    //       } else {
    //         setModules(initialDialogueModules);
    //       }
    //     });
    // }
    setBot({
      name: 'test',
      strategy: 'elo',
      status: 'TESTING',
      description: 'Elo',
      dialogue_modules: initialDialogueModules,
    });
  }, [botId]);

  const [newModuleIndex, setNewModuleIndex] = useState<number | null>(null);

  const questionModules = modules.filter(
    (module) => module.type === 'QUESTION',
  ).length;

  const addModule = (index: number) => {
    const newModule: DialogueModuleRequest = {
      type: 'QUESTION',
      title: `QUESTION ${questionModules + 1}`,
      bot_statement: 'This is a new module.',
      bot_statement_shortened: '',
      question_type: 'OPEN',
      question_data: {},
      disabled: false,
    };

    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      updatedModules.splice(index, 0, newModule); // Insert new module at the given index
      setNewModuleIndex(index);
      return updatedModules;
    });
  };

  const handleUpdateModule = (
    index: number,
    updatedModule: DialogueModuleRequest,
  ) => {
    setModules((prevModules) => {
      const newModules = [...prevModules];
      newModules[index] = updatedModule;
      return newModules;
    });
  };

  const handleSaveChanges = () => {
    if (botId && bot) {
      const newBot = { ...bot, dialogue_modules: modules };
      const data = {
        id: botId,
        data: newBot,
      };

      console.log(data);
      alert('brak backendu');
      setSuccessPopupVisible(true);
    }
  };
  const deleteModule = (index: number) => {
    setModules((prevModules) => prevModules.filter((_, i) => i !== index));
  };

  const onSettingsClick = () => {
    navigate(`/bots/graph/${botId}/settings`);
  };

  if (!bot) return <div>Loading...</div>;

  return (
    <MainContainer>
      <Sidebar hasCampaignAccess={false} strategyId={strategy} />
      <ContentContainer style={{ flexDirection: 'column' }}>
        {successPopupVisible && (
          <SuccessPopup
            text="Changes saved successfully."
            onClose={() => setSuccessPopupVisible(false)}
          />
        )}

        <GraphPanelNavbarContainer>
          <div onClick={() => navigate('/dashboard/presets/dialogues')}>
            <ArrowLeftIcon $pointer style={{ width: 24, height: 24 }} />
          </div>
          <H2 style={{ fontSize: 16, fontWeight: 600 }}>
            {selectedWorkspaceName}/ Bot {bot?.name}
          </H2>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 8,
              alignItems: 'center',
            }}
          >
            <Button $size="S" onClick={handleSaveChanges}>
              Save changes
            </Button>

            <button onClick={onSettingsClick}>
              <SettingsIcon $pointer style={{ width: 24, height: 24 }} />
            </button>
          </div>
        </GraphPanelNavbarContainer>
        {/* {successPopupMessage !== '' && (
        <CustomAlert
          text={successPopupMessage}
          onClose={() => setSuccessPopupMessage('')}
        />
      )} */}

        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <GraphDisplay
            modules={modules}
            onUpdateModule={handleUpdateModule}
            onDeleteModule={deleteModule}
            newModuleIndex={newModuleIndex}
            setNewModuleIndex={setNewModuleIndex}
            addModule={addModule}
          />
        </div>
      </ContentContainer>
    </MainContainer>
  );
};

export default GraphPanel;

export const GraphPanelContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px;
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 12px;
  gap: 16px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  max-height: 100vh;
  overflow: auto;
`;

export const GraphPanelNavbarContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 8px;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  background: ${COLOR_NEUTRAL_30};
  justify-content: space-between;
`;
