import React, { useState } from 'react';
import { Button, H2, H4 } from '../../../../../../App.style';
import {
  CustomTokensListActionsContainer,
  CustomTokensRowContainer,
  DialogueTokensContentContainer,
  DialogueTokensLeftContainer,
  DialogueTokensRightContainer,
  DialogueTokensWrapper,
} from './DialogueTokens.style';
import { allBotTokens } from '../../../configuration/tokenInputComponents/BotsTokens';
import { TokenButton } from '../../tokenInput/TokenInput.style';
import { BotConfigurationRequest } from '../../../../../../../generated/tenants/Api';
import { VindicationContentContainer } from '../../Vindication.style';
import CustomTokenFormModal from './CustomTokenFormModal';
import { WandIcon } from '../../../../../../../resources/icons-new/WandIcon';

interface DialogueTokensProps {
  vindicationDialogue?: BotConfigurationRequest;
  setVindicationDialogue?: React.Dispatch<
    React.SetStateAction<BotConfigurationRequest>
  >;
}

const DialogueTokens: React.FC<DialogueTokensProps> = ({
  vindicationDialogue,
  setVindicationDialogue,
}) => {
  const [isCustomTokenModalVisible, setIsCustomTokenModalVisible] =
    useState(false);
  const [selectedCustomToken, setSelectedCustomToken] = useState<string>();

  const handleCloseCustomTokenModal = () => {
    setIsCustomTokenModalVisible(false);
    setSelectedCustomToken(undefined);
  };

  const handleEditCustomTokenClick = (token: string) => {
    setSelectedCustomToken(token);
    setIsCustomTokenModalVisible(true);
  };

  const addCustomToken = (token: string) => {
    if (setVindicationDialogue)
      setVindicationDialogue((vindicationDialogue) => {
        const updatedTokens = vindicationDialogue.custom_tokens
          ? [...vindicationDialogue.custom_tokens, token]
          : [token];
        return { ...vindicationDialogue, custom_tokens: updatedTokens };
      });
  };

  const editCustomToken = (oldToken: string, newToken: string) => {
    if (setVindicationDialogue)
      setVindicationDialogue((currentConfig) => {
        if (!currentConfig.custom_tokens) {
          // Optionally handle the case where there are no tokens
          return currentConfig;
        }

        const tokenIndex = currentConfig.custom_tokens.findIndex(
          (token) => token === oldToken,
        );
        if (tokenIndex !== -1) {
          const updatedTokens = [...currentConfig.custom_tokens];
          updatedTokens[tokenIndex] = newToken;
          return { ...currentConfig, custom_tokens: updatedTokens };
        }

        return currentConfig;
      });
  };

  const deleteCustomToken = (token: string) => {
    if (setVindicationDialogue)
      setVindicationDialogue((currentConfig) => {
        if (!currentConfig.custom_tokens) {
          // Optionally handle the case where there are no tokens to delete
          return currentConfig;
        }

        const updatedTokens = currentConfig.custom_tokens.filter(
          (t) => t !== token,
        );
        return { ...currentConfig, custom_tokens: updatedTokens };
      });
  };

  const cleanTokenName = (tokenName: string): string => {
    const cleanedName = tokenName
      .replace('%_CUSTOM_', '')
      .replace('/VERBATIM', '');
    return cleanedName;
  };

  let customTokenModal;
  if (isCustomTokenModalVisible) {
    customTokenModal = (
      <CustomTokenFormModal
        selectedCustomToken={selectedCustomToken}
        closeModal={handleCloseCustomTokenModal}
        addCustomToken={addCustomToken}
        editCustomToken={editCustomToken}
        cleanTokenName={cleanTokenName}
        customTokensList={vindicationDialogue?.custom_tokens ?? []}
      />
    );
  }
  return (
    <VindicationContentContainer>
      {customTokenModal}
      <H2 $selected>Tokens</H2>

      <DialogueTokensWrapper>
        <DialogueTokensLeftContainer>
          <H4 $medium>Available tokens</H4>
          <DialogueTokensContentContainer
            style={{ flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {allBotTokens.map((token) => {
              return (
                <TokenButton key={`token-${token.id}-available`}>
                  {token.displayText}
                </TokenButton>
              );
            })}
          </DialogueTokensContentContainer>
        </DialogueTokensLeftContainer>

        <DialogueTokensRightContainer>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <H4 $medium>Custom tokens </H4>
            <WandIcon />
          </div>
          <DialogueTokensContentContainer style={{ flexDirection: 'column' }}>
            {vindicationDialogue?.custom_tokens ? (
              vindicationDialogue.custom_tokens.map((token, i) => {
                return (
                  <CustomTokensRowContainer key={`${token}-${i}`}>
                    <div>
                      <TokenButton>
                        <WandIcon />
                        {cleanTokenName(token)}{' '}
                        {token.includes('/VERBATIM') && (
                          <span
                            style={{
                              fontStyle: 'italic',
                              fontWeight: 300,
                            }}
                          >
                            / przeliterowany
                          </span>
                        )}
                      </TokenButton>
                    </div>

                    <CustomTokensListActionsContainer>
                      <H4 onClick={() => handleEditCustomTokenClick(token)}>
                        edit
                      </H4>
                      <H4 onClick={() => deleteCustomToken(token)}>delete</H4>
                    </CustomTokensListActionsContainer>
                  </CustomTokensRowContainer>
                );
              })
            ) : (
              <CustomTokensRowContainer>
                <H4>No tokens to show</H4>
              </CustomTokensRowContainer>
            )}

            <div>
              <Button
                $size="S"
                $styleType="NORMAL"
                onClick={() => setIsCustomTokenModalVisible(true)}
                disabled
              >
                Create new
              </Button>
            </div>
          </DialogueTokensContentContainer>
        </DialogueTokensRightContainer>
      </DialogueTokensWrapper>
    </VindicationContentContainer>
  );
};

export default DialogueTokens;
