import moment from 'moment';
import React from 'react';
import { H3 } from '../../../App.style';
import {
  BotConfiguration,
  CustomFaas,
} from '../../../../generated/tenants/Api';
import PresetListPreview from '../PresetListPreview';
import {
  AddPresetIconContainer,
  CreateNewPresetContainer,
  CreatePresetButtonContainer,
  ListContainer,
} from '../PresetsPanel.style';
import { NewPresetIcon } from '../../../../resources/icons-new/NewPresetIcon';
import { EuiAccordion } from '@elastic/eui';
import { DialoguesListContainer } from './Dialogues.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface DialoguesListProps {
  findProcessNamesByDialoguesId(id: string): string[];
  dialoguesList: BotConfiguration[];
  createCustomDialogue: () => void;
  handleDeleteDialoguePreset: (id: string) => void;
  goToDetailedDialogue: (id: string) => void;

  //custom faas
  customFaasesList: CustomFaas[];
  createCustomFaas: () => void;
  goToDetailedCustomFaas: (customFaas: string) => void;
  handleDeleteCustomFaas: (id: string) => void;
  findProcessNamesByCustomFaasId(id: string): string[];
  handleDuplicateCustomFaas: (id: string) => void;
  isSuperAdmin: boolean;
}
const DialoguesList: React.FC<DialoguesListProps> = ({
  dialoguesList,
  createCustomDialogue,
  findProcessNamesByDialoguesId,
  handleDeleteDialoguePreset,
  goToDetailedDialogue,
  customFaasesList,
  createCustomFaas,
  goToDetailedCustomFaas,
  handleDeleteCustomFaas,
  findProcessNamesByCustomFaasId,
  handleDuplicateCustomFaas,
  isSuperAdmin,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleDuplicatePreset = (id: string) => {
    alert('not done yet');
    console.log(id);
  };

  const handleDetailedClick = (id: string, isCustom?: boolean) => {
    if (isCustom) {
      goToDetailedDialogue(id);
    } else {
      alert('Detailed view is available only for custom bots');
    }
  };

  return (
    <ListContainer style={{ flexDirection: 'column' }}>
      <EuiAccordion
        id={'dialogues_list'}
        buttonContent="Dialogues"
        buttonContentClassName="eui-textTruncate"
        initialIsOpen
        paddingSize="none"
      >
        <DialoguesListContainer>
          {dialoguesList.map((dialogue, index) => {
            const formattedCreated = moment(dialogue.created_at).format(
              'DD/MM/YYYY, HH:mm',
            );
            const fomrattedModified = moment(dialogue.updated_at).format(
              'DD/MM/YYYY, HH:mm',
            );

            const usedIn = findProcessNamesByDialoguesId(dialogue.id);
            return (
              <PresetListPreview
                type={'dialogues'}
                key={`dialogue-${index}`}
                index={index}
                onClick={() =>
                  handleDetailedClick(dialogue.id, dialogue.is_custom)
                }
                presetId={dialogue.id}
                presetName={dialogue.name}
                createDate={formattedCreated}
                modifiedDate={fomrattedModified}
                usedIn={usedIn}
                handleDeletePreset={handleDeleteDialoguePreset}
                handleDuplicatePreset={handleDuplicatePreset}
                botTemplate={dialogue.template}
                isCustomBot={dialogue.is_custom}
                disableDots={!isSuperAdmin}
              />
            );
          })}

          {isSuperAdmin && (
            <>
              <CreateNewPresetContainer
                onClick={() => navigate('/bots/graph/bot')}
              >
                <CreatePresetButtonContainer>
                  <AddPresetIconContainer>
                    <NewPresetIcon
                      style={{
                        position: 'absolute',
                        left: 17,
                        top: 17,
                        width: 15,
                        height: 15,
                      }}
                    />
                  </AddPresetIconContainer>
                  <H3>{t('common.createNew')}</H3>
                </CreatePresetButtonContainer>
              </CreateNewPresetContainer>

              <CreateNewPresetContainer onClick={createCustomDialogue}>
                <CreatePresetButtonContainer>
                  <AddPresetIconContainer>
                    <NewPresetIcon
                      style={{
                        position: 'absolute',
                        left: 17,
                        top: 17,
                        width: 15,
                        height: 15,
                      }}
                    />
                  </AddPresetIconContainer>
                  <H3>{t('common.createNew')} custom</H3>
                </CreatePresetButtonContainer>
              </CreateNewPresetContainer>
            </>
          )}
        </DialoguesListContainer>
      </EuiAccordion>

      <EuiAccordion
        id={'lambda_faas'}
        buttonContent="Lambda FAAS"
        buttonContentClassName="eui-textTruncate"
        paddingSize="none"
        initialIsOpen
      >
        <DialoguesListContainer>
          {customFaasesList.map((customFaas, index) => {
            const formattedCreated = moment(customFaas.created_at).format(
              'DD/MM/YYYY, HH:mm',
            );
            const fomrattedModified = moment(customFaas.updated_at).format(
              'DD/MM/YYYY, HH:mm',
            );

            const usedIn = findProcessNamesByCustomFaasId(customFaas.id);
            return (
              <PresetListPreview
                type={'custom_faas'}
                key={`custom-faas-${index}`}
                index={index}
                onClick={() => goToDetailedCustomFaas(customFaas.id)}
                presetId={customFaas.id}
                presetName={customFaas.name}
                createDate={formattedCreated}
                modifiedDate={fomrattedModified}
                usedIn={usedIn}
                handleDeletePreset={handleDeleteCustomFaas}
                handleDuplicatePreset={handleDuplicateCustomFaas}
                customFaasType={customFaas.lambda_type}
                disableDots={!isSuperAdmin}
              />
            );
          })}

          {isSuperAdmin && (
            <CreateNewPresetContainer onClick={createCustomFaas}>
              <CreatePresetButtonContainer>
                <AddPresetIconContainer>
                  <NewPresetIcon
                    style={{
                      position: 'absolute',
                      left: 17,
                      top: 17,
                      width: 15,
                      height: 15,
                    }}
                  />
                </AddPresetIconContainer>
                <H3>{t('common.createNew')}</H3>
              </CreatePresetButtonContainer>
            </CreateNewPresetContainer>
          )}
        </DialoguesListContainer>
      </EuiAccordion>
    </ListContainer>
  );
};

export default DialoguesList;
