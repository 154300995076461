import { Handle, Position } from '@xyflow/react';
import { styled } from 'styled-components';

const DeleteModuleNode: React.FC<any> = ({ data }) => {
  return (
    <DeleteNodeContainer>
      <div
        style={{
          position: 'absolute',
          left: -13,
          top: -14,
          background: 'black',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          height: 26,
          width: 26,
          zIndex: 1000,
        }}
        onClick={(e) => {
          e.stopPropagation();
          data.onClick();
        }}
      >
        X
      </div>

      <Handle
        type="target"
        position={Position.Top}
        style={{ background: 'white', border: '1px solid black' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: 'white', border: '1px solid black' }}
      />
    </DeleteNodeContainer>
  );
};

export default DeleteModuleNode;

export const DeleteNodeContainer = styled.div`
  border-radius: 6px;
  background-color: red;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  position: relative;
`;
