import { useState } from 'react';

import { styled } from 'styled-components';
import {
  EuiFormRow,
  EuiHealth,
  EuiSuperSelect,
  EuiSwitch,
  EuiTextArea,
} from '@elastic/eui';
import { PresetsIcon } from '../../../../resources/icons-new/PresetsIcon';
import { VoicePresetIcon } from '../../../../resources/icons-new/VoicePresetIcon';
import {
  COLOR_NEUTRAL_50,
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_80,
  Button,
} from '../../../App.style';
import { DialogueModuleRequest, StyledText } from '../sample_data';

export interface QuestionContent {
  isOn: boolean;
  text: string;
  followUpQuestion?: boolean;
  followUpText?: string;
}

type QuestionMapped = Record<string, QuestionContent>;

export interface BotModule {
  type: 'identification' | 'question' | 'goodbye' | 'welcome';
  title: string;
  botStatement: string;
  botStatementShortened: string;
  disabled?: boolean;
  questionType: 'YES/NO' | 'OPEN' | 'SCALE' | 'LEVEL';
  questionData: QuestionMapped;
}

interface ModuleFormProps {
  module: DialogueModuleRequest;
  onUpdate: (updatedModule: DialogueModuleRequest) => void;
  onDelete: () => void;
  isFirstQuestion?: boolean;
}

const ModuleForm: React.FC<ModuleFormProps> = ({
  module,
  onUpdate,
  onDelete,
  isFirstQuestion,
}) => {
  // Function to handle question type change
  const handleQuestionTypeChange = (
    newType: DialogueModuleRequest['question_type'],
  ) => {
    let newQuestionData: any;

    if (newType === 'OPEN') {
      newQuestionData = { placeholder: '' };
    } else {
      newQuestionData = {};
      if (newType === 'YESNO') {
        newQuestionData = {
          yes: { isOn: true, text: '' },
          no: { isOn: true, text: '' },
        };
      } else if (newType === 'SCALE') {
        newQuestionData = {
          '1-8': { isOn: true, text: '' },
          '9-10': { isOn: true, text: '' },
        };
      } else if (newType === 'LEVEL') {
        newQuestionData = {
          easy: { isOn: true, text: '' },
          medium: { isOn: true, text: '' },
          high: { isOn: true, text: '' },
        };
      }
    }

    onUpdate({
      ...module,
      question_type: newType,
      question_data: newQuestionData,
    });
  };

  // Function to handle changes for module title, bot statement, and question data
  const handleChange = (
    key: string | null,
    field:
      | keyof QuestionContent
      | 'title'
      | 'bot_statement'
      | 'disabled'
      | 'bot_statement_shortened',
    value: any,
  ) => {
    if (key === null) {
      // Update module-level fields (title, botStatement)
      onUpdate({ ...module, [field]: value });
    } else if (module.question_type !== 'OPEN') {
      // Update question content

      const updatedQuestionData = {
        ...module.question_data,
        [key]: { ...module.question_data[key], [field]: value },
      };
      onUpdate({ ...module, question_data: updatedQuestionData });
    }
  };

  const [selectedTab, setSelectedTab] = useState<1 | 2>(1);

  const moduleQuestionSwitchDisabled =
    module.type === 'GOODBYE' || module.question_type === 'OPEN';

  let sortedModules = Object.keys(module.question_data || {});
  if (module.question_type === 'YESNO') {
    const fixedOrder = ['yes', 'no'];
    sortedModules = sortedModules.sort(
      (a, b) =>
        fixedOrder.indexOf(a.toLowerCase()) -
        fixedOrder.indexOf(b.toLowerCase()),
    );
  } else if (module.question_type === 'LEVEL') {
    const fixedOrder = ['easy', 'medium', 'high'];
    sortedModules = sortedModules.sort(
      (a, b) =>
        fixedOrder.indexOf(a.toLowerCase()) -
        fixedOrder.indexOf(b.toLowerCase()),
    );
  }
  console.log(sortedModules);

  const options = [
    {
      value: 'YESNO',
      inputDisplay: (
        <EuiHealth color="subdued" style={{ lineHeight: 'inherit' }}>
          Yes / No
        </EuiHealth>
      ),
      'data-test-subj': 'option-warning',
      disabled: true,
    },
    {
      value: 'OPEN',
      inputDisplay: (
        <EuiHealth color="warning" style={{ lineHeight: 'inherit' }}>
          OPEN
        </EuiHealth>
      ),
      'data-test-subj': 'option-minor',
    },
    {
      value: 'SCALE',
      inputDisplay: (
        <EuiHealth color="danger" style={{ lineHeight: 'inherit' }}>
          SCALE
        </EuiHealth>
      ),
      'data-test-subj': 'option-critical',
    },
    {
      value: 'LEVEL',
      inputDisplay: (
        <EuiHealth color="danger" style={{ lineHeight: 'inherit' }}>
          Level (Easy, Medium, High)
        </EuiHealth>
      ),
      'data-test-subj': 'option-critical',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 16,
        padding: 8,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          background: '#E4EDF8',
          padding: 4,
          borderRadius: 1,
          boxSizing: 'border-box',
          marginBottom: 1,
        }}
      >
        <ModuleQuestionSwitchButton
          $selected={selectedTab === 1}
          onClick={() => setSelectedTab(1)}
        >
          <PresetsIcon style={{ width: 20, height: 20 }} />
          <StyledText $fontSize={12}>Bot statement</StyledText>
        </ModuleQuestionSwitchButton>

        <ModuleQuestionSwitchButton
          $selected={selectedTab === 2}
          $disabled={moduleQuestionSwitchDisabled}
          onClick={() => {
            if (!moduleQuestionSwitchDisabled) setSelectedTab(2);
          }}
        >
          <VoicePresetIcon style={{ width: 20, height: 20 }} />
          <StyledText $fontSize={12}>Exit</StyledText>
        </ModuleQuestionSwitchButton>
      </div>

      {selectedTab === 1 && (
        <>
          {module.type !== 'START' && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <StyledText $fontSize={14} $weight={600}>
                Question type:{' '}
              </StyledText>

              <EuiFormRow label="Status" helpText="Cos tam cos tam">
                <EuiSuperSelect
                  style={{ minWidth: 300 }}
                  options={options}
                  valueOfSelected={module.question_type}
                  onChange={(e) =>
                    handleQuestionTypeChange(
                      e as DialogueModuleRequest['question_type'],
                    )
                  }
                />
              </EuiFormRow>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <StyledText
              $weight={600}
              $fontSize={14}
              style={{ marginBottom: 8 }}
              $color={COLOR_NEUTRAL_50}
            >
              Bot statement
            </StyledText>
            <EuiTextArea
              placeholder="Placeholder text"
              value={module.bot_statement}
              onChange={(e) =>
                handleChange(null, 'bot_statement', e.target.value)
              }
            />

            <StyledText
              $fontSize={10}
              $color={'#666664'}
              style={{ alignSelf: 'flex-end' }}
            >
              {module.bot_statement.length}/500
            </StyledText>

            {module.type === 'GOODBYE' && (
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  width: '95%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 1.5,
                  padding: 1,
                  marginTop: -2,
                  background: COLOR_NEUTRAL_10,
                }}
              >
                papa akcja: rozłączenie się
              </div>
            )}
          </div>

          {(module.type === 'QUESTION' || module.type === 'IDENTYFICATION') && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <StyledText
                  $weight={600}
                  $fontSize={14}
                  style={{ marginBottom: 8 }}
                  $color={COLOR_NEUTRAL_50}
                >
                  Shortened bot statement (in case of repetition)
                </StyledText>

                <EuiTextArea
                  placeholder="Placeholder text"
                  value={module.bot_statement_shortened}
                  onChange={(e) =>
                    handleChange(
                      null,
                      'bot_statement_shortened',
                      e.target.value,
                    )
                  }
                />

                <StyledText
                  $fontSize={10}
                  $color={'#666664'}
                  style={{ alignSelf: 'flex-end' }}
                >
                  {module.bot_statement_shortened.length}/500
                </StyledText>
              </div>
            </>
          )}
        </>
      )}

      {selectedTab === 2 && (
        <>
          {module.question_type !== 'OPEN' && (
            <div style={{ marginTop: 10 }}>
              <StyledText $fontSize={14} $weight={400}>
                <b>Exit</b> (bot reaction to specified answers)
              </StyledText>
            </div>
          )}

          {module.question_type !== 'OPEN' &&
            module.question_data &&
            Object.entries(module.question_data)
              .sort((a, b) => {
                if (module.question_type === 'YESNO') {
                  const fixedOrder = ['yes', 'no'];
                  return (
                    fixedOrder.indexOf(a[0].toLowerCase()) -
                    fixedOrder.indexOf(b[0].toLowerCase())
                  );
                }
                if (module.question_type === 'LEVEL') {
                  const fixedOrder = ['easy', 'medium', 'high'];
                  return (
                    fixedOrder.indexOf(a[0].toLowerCase()) -
                    fixedOrder.indexOf(b[0].toLowerCase())
                  );
                }

                return 0;
              })
              .map(([key, content]: [string, any]) => (
                <div
                  key={key}
                  style={{
                    border: '1px solid #E2E8F0',
                    boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                    padding: '8px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    boxSizing: 'border-box',
                    borderRadius: 6,
                    gap: 16,
                    marginBottom: 16,
                  }}
                >
                  <StyledText $fontSize={12} $color={COLOR_NEUTRAL_80}>
                    Client answer: {key.toUpperCase()}
                  </StyledText>

                  <EuiTextArea
                    placeholder="Placeholder text"
                    aria-label="Use aria labels when no actual label is in use"
                    value={content.text}
                    onChange={(e) => handleChange(key, 'text', e.target.value)}
                  />

                  {key === 'no' && module.question_type === 'YESNO' && (
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        width: '95%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 6,
                        padding: 8,
                        marginTop: -16,
                        background: COLOR_NEUTRAL_10,
                      }}
                    >
                      akcja: rozłączenie się
                    </div>
                  )}

                  {module.type === 'QUESTION' && !content.followUpQuestion && (
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleChange(key, 'followUpQuestion', true)
                        }
                      >
                        + Add follow up question
                      </Button>
                    </div>
                  )}

                  {content.followUpQuestion && (
                    <div
                      style={{
                        width: '100%',
                        position: 'relative',
                        marginTop: 16,
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: -10,
                          cursor: 'pointer',
                          zIndex: 100,
                        }}
                        onClick={() =>
                          handleChange(key, 'followUpQuestion', false)
                        }
                      >
                        X delete follow up
                      </div>
                      <EuiTextArea
                        placeholder="Follow-up text"
                        value={content.followUpText || ''}
                        onChange={(e) =>
                          handleChange(key, 'followUpText', e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
              ))}

          {/* is module active or not switch */}
          {module.type === 'IDENTYFICATION' && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 16,
              }}
            >
              <EuiSwitch
                label="Module is active"
                checked={!module.disabled}
                onChange={(e) =>
                  handleChange(null, 'disabled', !e.target.checked)
                }
              />
            </div>
          )}
        </>
      )}

      {module.type === 'QUESTION' && !isFirstQuestion && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Button onClick={onDelete}>Remove this module</Button>
        </div>
      )}
    </div>
  );
};

export default ModuleForm;

const ModuleQuestionSwitchButton = styled.div<{
  $selected?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 30px;
  cursor: ${({ $disabled }) =>
    $disabled ? 'not-allowed' : 'pointer'} !important;
  border-radius: 4px;

  background: ${({ $selected }) => ($selected ? '#fff' : '#E4EDF8')};

  &:hover {
    background: ${({ $disabled }) => ($disabled ? '' : '#fff')};
  }
`;
