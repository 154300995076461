import {
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_PRIMARY_1,
} from '../../../../../App.style';
import styled from 'styled-components';
import { COLOR_NEUTRAL_10 } from '../../../../../App.style';
import { EuiPanel } from '@elastic/eui';

export const BotStatementEditContainer = styled.div`
  width: 100%;
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const LeftPanel = styled.div`
  padding: 8px 16px;
  width: 50%;
  border-radius: 5px;

  background: ${COLOR_NEUTRAL_10};

  display: flex;
  flex-direction: column;
`;

export const TokensContainer = styled.div`
  margin-top: 16px;
`;

export const TokensOptionsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TextsContainer = styled.div`
  margin-top: 30px;
  margin-left: 8px;
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;

export const TabButton = styled.button<{ $selected?: boolean }>`
  display: inline-flex;
  padding: 3.027px 6.055px;
  align-items: center;
  gap: 6.055px;

  border-radius: 5.811px;
  background: #fff;

  border: ${({ $selected }) => $selected && `1px solid ${COLOR_PRIMARY_1}`};
  color: ${({ $selected }) => $selected && COLOR_PRIMARY_1};

  box-shadow: 0px 2px 2px 0px rgba(175, 175, 175, 0.3);
`;

export const RightPanel = styled.div`
  width: 50%;
  height: 300px;
  background: ${COLOR_NEUTRAL_10};
  overflow: auto;
  border-radius: 5px;
`;

export const TokenDisplayContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;
`;

export const StyledPanel = styled(EuiPanel)`
  position: relative;
  display: flex;
  align-items: center;

  border: 0.5px solid ${COLOR_NEUTRAL_40};
`;

export const StyledPanelSmallText = styled.div`
  position: absolute;
  top: 3px;
  left: 15px;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const BottomContainer = styled.div`
  margin-top: 16px;
  width: 60%;
  margin-bottom: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid #9aa5b1;
`;

export const TextPreviewContainer = styled.div`
  display: flex;
  gap: 3px;
  margin-top: 6px;
  border: 1px dashed ${COLOR_NEUTRAL_40};
  padding: 8px;
  border-radius: 5px;
  min-height: 30px;
  flex-wrap: wrap;
`;

//TokenSelector

export const TokenSelectorContainer = styled.div`
  width: calc(100% - 55px);
  margin-left: 55px;
  margin-top: 8px;
  padding: 8px;

  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  background: white;
  border-radius: 5px;
  height: 200px;
`;

export const TokensAvailableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: 100%;
  overflow: auto;
`;

export const TokensSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;

  position: relative;
`;

export const TokensSelectedContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 8px;

  max-height: 120px;

  overflow: auto;
`;

export const TokensSelectedBottomContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  right: 0px;
  bottom: 0px;
`;

export const TokenButton = styled.button`
  display: inline-flex;
  padding: 3.027px 6.055px;
  align-items: center;

  gap: 6.055px;

  border-radius: 5.811px;
  background: #fff;

  border: 1px solid ${COLOR_NEUTRAL_20};

  box-shadow: 0px 2px 2px 0px rgba(175, 175, 175, 0.3);
`;

export const TokenPrzypadekButton = styled.button`
  display: inline-flex;

  padding: 3.027px 6.055px;

  height: 32px;
  align-items: center;
  gap: 6.055px;

  border-radius: 5.811px;
  background: #fff;

  border: 1px solid ${COLOR_NEUTRAL_20};

  box-shadow: 0px 2px 2px 0px rgba(175, 175, 175, 0.3);
`;

//TextToken
export const TextTokenContainer = styled.div`
  width: 400px;
  margin-left: 45px;
  margin-top: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TextTokenInputContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const TextTokenButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

//visible

export const TokenInputPreviewContainer = styled.div`
  position: relative;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-end;
  background: white;
  border-radius: 5px;
  min-height: 80px;

  width: 560px;
  gap: 8px;
`;

export const TokenInputPreviewText = styled.div`
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow-wrap: break-word;
`;

export const CustomTokenSpan = styled.span`
  background: #dedcfa;
  color: #473bf0;
  border-radius: 3px;
  padding: 2px 4px;
`;

export const DialogueTokenSpan = styled.span`
  background: #cfecfd;
  color: #127fbf;
  border-radius: 3px;
  padding: 2px 4px;
`;
