import React, { useEffect, useState } from 'react';
import SuccessPopup from '../../../common/popups/SuccessPopup';
import { ArrowLeftIcon } from '../../../resources/icons-new/ArrowLeftIcon';
import { MainContainer, ContentContainer, H2, Button } from '../../App.style';
import Sidebar from '../sidebar/Sidebar';
import { GraphPanelNavbarContainer } from './modules/GraphPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../common/hooks';
import {
  selectStrategiesList,
  selectStrategyId,
} from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  StyledText,
  TemporaryBotRequest,
  initialDialogueModules,
} from './sample_data';
import { selectUserSettings } from '../settings/userSettings/api/userSettingsSlice';
import {
  EuiText,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTextArea,
  EuiSelect,
  EuiPanel,
  EuiButtonEmpty,
} from '@elastic/eui';
import DialogueTokens from './old/vindication/components/customTokens/DialogueTokens';

interface Tab {
  id: string;
  name: string;
  content: React.ReactNode;
}

interface VerticalTabsProps {
  tabs: Tab[];
  selectedTab: number;
  onSelectTab: (index: number) => void;
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({
  tabs,
  selectedTab,
  onSelectTab,
}) => {
  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      {tabs.map((tab, index) => (
        <EuiFlexItem key={tab.id} grow={false}>
          <EuiButtonEmpty
            color="text"
            size="s"
            isSelected={selectedTab === index}
            onClick={() => onSelectTab(index)}
          >
            {tab.name}
          </EuiButtonEmpty>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

const BotSettings = () => {
  const strategy = useAppSelector(selectStrategyId);
  const [successPopupVisible, setSuccessPopupVisible] =
    useState<boolean>(false);

  const [selectedWorkspaceName, setSelectedWorkspaceName] =
    useState<string>('');
  const apiWorkspacesList = useAppSelector(selectStrategiesList);
  const apiUserSettings = useAppSelector(selectUserSettings);

  useEffect(() => {
    if (apiUserSettings.value && apiWorkspacesList) {
      const selectedWorkspace = apiWorkspacesList.find(
        (item) => item.id === strategy,
      );

      if (selectedWorkspace)
        setSelectedWorkspaceName(selectedWorkspace.name.split('-')[0]);
    }
  }, []);

  const { botId } = useParams();

  const navigate = useNavigate();

  const [bot, setBot] = useState<TemporaryBotRequest>({
    name: 'test',
    strategy: 'elo',
    status: 'TESTING',
    description: 'Elo',
    dialogue_modules: initialDialogueModules,
  });
  const [botName, setBotName] = useState<string>('');
  const [botDescription, setBotDescription] = useState<string>('');
  const [successPopupMessage, setSuccessPopupMessage] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (botId) {
      // Replace this with your async call
      console.log('Fetching bot details for:', botId);
      // Simulate response:
      const fakeBot = { name: 'Test Bot', description: 'A sample bot' };
      // setBot(fakeBot);
      setBotName(fakeBot.name);
      setBotDescription(fakeBot.description || '');
      setBot({
        name: 'test',
        strategy: 'elo',
        status: 'TESTING',
        description: 'Elo',
        dialogue_modules: initialDialogueModules,
      });
      console.log(successPopupMessage);
      setSuccessPopupMessage('e');
    }
  }, [botId]);

  const handleSaveChanges = () => {
    if (botId && bot) {
      const newBot = bot;
      const data = {
        id: botId,
        data: newBot,
      };

      console.log(data);
      alert('brak backendu');
      setSuccessPopupVisible(true);
    }
  };

  const tabs: Tab[] = [
    {
      id: 'general',
      name: 'General',
      content: (
        <div>
          <EuiFlexGroup direction="column" gutterSize="m">
            {/* Bot Name */}
            <EuiFlexItem>
              <EuiText size="s">
                <strong>Bot name</strong>
              </EuiText>
              <EuiFieldText
                fullWidth
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
            </EuiFlexItem>

            {/* Bot Location */}
            <EuiFlexItem>
              <EuiText size="s">
                <strong>Bot location</strong>
              </EuiText>
              <EuiFlexGroup alignItems="center" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiIcon type="folderOpen" />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size="s">
                    {selectedWorkspaceName} / Bot testowy blablalaalalal
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            {/* Bot Description */}
            <EuiFlexItem>
              <EuiText size="s">
                <strong>Bot description</strong>
              </EuiText>
              <EuiTextArea
                fullWidth
                value={botDescription}
                onChange={(e) => setBotDescription(e.target.value)}
                rows={4}
              />
            </EuiFlexItem>

            {/* Bot Voice (postMVP) #1 */}
            <EuiFlexItem>
              <EuiText size="s">
                <strong>Bot voice (postMVP)</strong>
              </EuiText>
              <EuiSelect
                fullWidth
                options={[{ value: 'test', text: 'English' }]}
                value="test"
                onChange={(e) => console.log(e)}
              />
            </EuiFlexItem>

            {/* Bot Voice (postMVP) #2 */}
            <EuiFlexItem>
              <EuiText size="s">
                <strong>Bot voice (postMVP)</strong>
              </EuiText>
              <EuiSelect
                fullWidth
                options={[{ value: 'test', text: 'English' }]}
                value="test"
                onChange={(e) => console.log(e)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      ),
    },
    {
      id: 'variables',
      name: 'Zmienne',
      content: (
        <div>
          <DialogueTokens />
        </div>
      ),
    },
    {
      id: 'uncertainties',
      name: 'Niepewnsosci',
      content: (
        <div>
          <EuiFlexGroup direction="column" gutterSize="m">
            {/* Bot Name */}
            <StyledText>Bot behaviour handle uncertainity</StyledText>
            <EuiFlexItem>
              <EuiText size="s">
                <strong>Unable to recognise</strong>
              </EuiText>
              <EuiFieldText
                placeholder="Repeat the question/"
                value={''}
                readOnly
                onChange={(e) => console.log(e.target.value)}
              />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <strong>Positive uncertainity</strong>
              </EuiText>
              <EuiFieldText
                placeholder="Repeat the question/"
                value={''}
                readOnly
                onChange={(e) => console.log(e.target.value)}
              />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <strong>Negative uncertainity</strong>
              </EuiText>
              <EuiFieldText
                placeholder="Repeat the question/"
                value={''}
                readOnly
                onChange={(e) => console.log(e.target.value)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      ),
    },
  ];

  return (
    <MainContainer>
      <Sidebar hasCampaignAccess={false} strategyId={strategy} />
      <ContentContainer style={{ flexDirection: 'column' }}>
        {successPopupVisible && (
          <SuccessPopup
            text="Changes saved successfully."
            onClose={() => setSuccessPopupVisible(false)}
          />
        )}

        <GraphPanelNavbarContainer>
          <div onClick={() => navigate(`/bots/graph/${botId}`)}>
            <ArrowLeftIcon $pointer style={{ width: 24, height: 24 }} />
          </div>
          <H2 style={{ fontSize: 16, fontWeight: 600 }}>
            {selectedWorkspaceName}/ Bot {bot?.name}
          </H2>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 8,
              alignItems: 'center',
            }}
          >
            <Button $size="S" onClick={handleSaveChanges}>
              Save changes
            </Button>
          </div>
        </GraphPanelNavbarContainer>
        {/* {successPopupMessage !== '' && (
    <CustomAlert
      text={successPopupMessage}
      onClose={() => setSuccessPopupMessage('')}
    />
  )} */}

        <EuiPanel paddingSize="l" style={{ height: '100%', width: '100%' }}>
          {/* {successPopupMessage && (
            <EuiCallOut
              title={successPopupMessage}
              color="success"
              iconType="check"
              onClose={() => setSuccessPopupMessage('')}
            />
          )} */}
          <EuiFlexGroup style={{ height: 224, width: '100%' }}>
            <EuiFlexItem grow={false} style={{ maxWidth: 150 }}>
              <VerticalTabs
                tabs={tabs}
                selectedTab={selectedTab}
                onSelectTab={setSelectedTab}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel paddingSize="l">{tabs[selectedTab].content}</EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </ContentContainer>
    </MainContainer>
  );
};

export default BotSettings;

// import { unwrapResult } from '@reduxjs/toolkit';
// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useAppDispatch, useAppSelector } from '../../../common/hooks';
// import { Button } from '../../App.style';
// import { selectUserSettings } from '../settings/userSettings/api/userSettingsSlice';
// import { TemporaryBotRequest, StyledText } from './sample_data';

// function a11yProps(index: number) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   };
// }

// const BotSettings = () => {
//   const dispatch = useAppDispatch();
//   const { botId } = useParams();
//   const navigate = useNavigate();

//   const [bot, setBot] = useState<TemporaryBotRequest>();

//   const [botName, setBotName] = useState<string>('');
//   const [botDescription, setBotDescription] = useState<string>('');

//   const [successPopupMessage, setSuccessPopupMessage] = useState<string>('');

//   useEffect(() => {
//     if (botId) {
//       // dispatch(getBotDetailedAsyncThunk(botId))
//       //   .then(unwrapResult)
//       //   .then((res) => {
//       //     setBot(res);

//       //     setBotName(res.name);
//       //     setBotDescription(res.description ?? '');
//       //   });
//       console.log(botId);
//     }
//   }, [botId]);

//   const [value, setValue] = useState(0);

//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     console.log(event);
//     setValue(newValue);
//   };

//   const handleSaveChanges = () => {
//     if (botId && bot) {
//       const newBot = { ...bot, name: botName, description: botDescription };
//       const data = {
//         id: botId,
//         data: newBot,
//       };

//     //   dispatch(updateBotAsyncThunk(data))
//     //     .then(unwrapResult)
//     //     .then((res) => {
//     //       if (res.status === 'success') {
//     //         setSuccessPopupMessage('Changes saved successfully.');
//     //         if (res.bot) {
//     //           setBot(res.bot);
//     //           setBotName(res.bot.name);
//     //           setBotDescription(res.bot.description ?? '');
//     //         }
//     //       } else {
//     //         alert('something went wrong, check console');
//     //       }
//     //     });
//     // }
//     alert('brak backendu')
//   };

//   const [selectedWorkspaceName, setSelectedWorkspaceName] =
//     useState<string>('');
//   const apiWorkspacesList = useAppSelector(selectWorkspacesList);
//   const apiUserSettings = useAppSelector(selectUserSettings);

//   useEffect(() => {
//     if (apiUserSettings.value && apiWorkspacesList) {
//       const selectedWorkspace = apiWorkspacesList.find(
//         (item) => item.id === apiUserSettings.value?.selected_strategy,
//       );

//       if (selectedWorkspace)
//         setSelectedWorkspaceName(selectedWorkspace.name.split('-')[0]);
//     }
//   }, []);

//   if (!bot) return <div>Loading...</div>;
//   return (
//     <PanelContainer style={{ padding: 0, borderRadius: 0, gap: 0 }}>
//       <Box
//         display={'flex'}
//         width={'100%'}
//         padding={'24px 8px'}
//         height={'56px'}
//         boxSizing={'border-box'}
//         alignItems={'center'}
//         style={{ background: COLOR_NEUTRAL_100 }}
//         justifyContent={'space-between'}
//       >
//         <IconButton onClick={() => navigate(`/dashboard/bots/${botId}`)}>
//           <KeyboardBackspaceOutlinedIcon />
//           <StyledText></StyledText>
//         </IconButton>
//         <StyledText $fontSize={16} $weight={600}>
//           {selectedWorkspaceName}/ Bot {bot?.name}
//         </StyledText>

//         <Box display={'flex'} justifyContent={'flex-start'} gap={1}>
//           <Button size="small" variant="contained" onClick={handleSaveChanges}>
//             Save changes
//           </Button>

//           <IconButton onClick={() => alert('?')}>
//             <SettingsOutlinedIcon color={'primary'} />
//           </IconButton>
//         </Box>
//       </Box>
//       {successPopupMessage !== '' && (
//         <CustomAlert
//           text={successPopupMessage}
//           onClose={() => setSuccessPopupMessage('')}
//         />
//       )}

//       <Box
//         sx={{
//           flexGrow: 1,
//           bgcolor: 'background.paper',
//           display: 'flex',
//           height: 224,
//           width: '100%',
//         }}
//       >
//         <Tabs
//           orientation="vertical"
//           variant="scrollable"
//           value={value}
//           onChange={handleChange}
//           aria-label="Vertical tabs bot settings"
//           sx={{
//             borderRight: 1,
//             borderColor: 'divider',
//           }}
//         >
//           <Tab
//             label="General"
//             icon={<SettingsOutlinedIcon />}
//             {...a11yProps(0)}
//             iconPosition="start"
//           />
//           <Tab
//             label="Zmienne"
//             icon={<CodeOutlinedIcon />}
//             iconPosition="start"
//             {...a11yProps(1)}
//           />
//           <Tab
//             label="Niepewnsosci"
//             icon={<HelpOutlineOutlinedIcon />}
//             iconPosition="start"
//             {...a11yProps(2)}
//           />
//         </Tabs>
//         <TabPanel value={value} index={0}>
//           <Box
//             display={'flex'}
//             flexDirection={'column'}
//             alignItems={'flex-start'}
//             width={'100%'}
//             boxSizing={'border-box'}
//           >
//             <StyledText
//               $weight={600}
//               $fontSize={14}
//               style={{ marginBottom: 8 }}
//               $color={COLOR_NEUTRAL_700}
//             >
//               Bot name
//             </StyledText>
//             <TextField
//               id="outlined-basic"
//               fullWidth
//               label=""
//               multiline
//               rows={1}
//               variant="outlined"
//               value={botName}
//               onChange={(e) => setBotName(e.target.value)}
//             />
//           </Box>

//           <Box
//             display={'flex'}
//             flexDirection={'column'}
//             alignItems={'flex-start'}
//             width={'100%'}
//             boxSizing={'border-box'}
//             marginTop={4}
//           >
//             <StyledText
//               $weight={600}
//               $fontSize={14}
//               style={{ marginBottom: 8 }}
//               $color={COLOR_NEUTRAL_700}
//             >
//               Bot location
//             </StyledText>
//             <Box
//               display={'flex'}
//               alignItems={'center'}
//               justifyContent={'center'}
//               gap={1}
//             >
//               <FolderOpenOutlinedIcon />
//               <StyledText
//                 $weight={400}
//                 $fontSize={16}
//                 style={{ marginBottom: 8 }}
//                 $color={COLOR_NEUTRAL_700}
//               >
//                 {selectedWorkspaceName} / Bot testowy blablalaalalal
//               </StyledText>
//             </Box>
//           </Box>

//           <Box
//             display={'flex'}
//             flexDirection={'column'}
//             alignItems={'flex-start'}
//             width={'100%'}
//             boxSizing={'border-box'}
//             marginTop={4}
//           >
//             <StyledText
//               $weight={600}
//               $fontSize={14}
//               style={{ marginBottom: 8 }}
//               $color={COLOR_NEUTRAL_700}
//             >
//               Bot description
//             </StyledText>
//             <TextField
//               id="outlined-basic"
//               fullWidth
//               label=""
//               multiline
//               rows={4}
//               minRows={3}
//               variant="outlined"
//               value={botDescription}
//               onChange={(e) => setBotDescription(e.target.value)}
//             />
//           </Box>

//           <Box
//             display={'flex'}
//             flexDirection={'column'}
//             alignItems={'flex-start'}
//             width={'100%'}
//             boxSizing={'border-box'}
//             marginTop={4}
//           >
//             <StyledText
//               $weight={600}
//               $fontSize={14}
//               style={{ marginBottom: 8 }}
//               $color={COLOR_NEUTRAL_700}
//             >
//               Bot voice (postMVP)
//             </StyledText>
//             <Select
//               size="small"
//               style={{ fontSize: 14 }}
//               variant="outlined"
//               value={'test'}
//               disabled
//               onChange={(e) => console.log(e)}
//               displayEmpty
//             >
//               <MenuItem value="test">English</MenuItem>
//             </Select>
//           </Box>

//           <Box
//             display={'flex'}
//             flexDirection={'column'}
//             alignItems={'flex-start'}
//             width={'100%'}
//             boxSizing={'border-box'}
//             marginTop={4}
//           >
//             <StyledText
//               $weight={600}
//               $fontSize={14}
//               style={{ marginBottom: 8 }}
//               $color={COLOR_NEUTRAL_700}
//             >
//               Bot voice (postMVP)
//             </StyledText>
//             <Select
//               size="small"
//               style={{ fontSize: 14 }}
//               variant="outlined"
//               value={'test'}
//               disabled
//               onChange={(e) => console.log(e)}
//               displayEmpty
//             >
//               <MenuItem value="test">English</MenuItem>
//             </Select>
//           </Box>
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           Zmienne
//         </TabPanel>
//         <TabPanel value={value} index={2}>
//           Variables used in bot
//         </TabPanel>
//       </Box>
//     </PanelContainer>
//   );
// };

// export default BotSettings;

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       style={{ width: 500 }}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }
