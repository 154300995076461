import {
  useNodesState,
  useEdgesState,
  Node,
  Background,
  Controls,
  MiniMap,
  ReactFlow,
} from '@xyflow/react';
import React, { useEffect, useState } from 'react';
import { COLOR_NEUTRAL_30, COLOR_NEUTRAL_50 } from '../../../App.style';
import { DownloadButton } from '../../campaigns/tabs/calls/Calls.styles';
import { DialogueModuleRequest } from '../sample_data';
import BotModuleFormDrawer from './BotModuleFormDrawer';
import AnswerNode from './nodes/AnswerNode';
import ClientAnswerNode from './nodes/ClientAnswerNode';
import DeleteModuleNode from './nodes/DeleteModuleNode';
import GroupConnectionNode from './nodes/GroupConnectionNode';
import MainNode from './nodes/MainNode';
import ModuleTitleNode from './nodes/ModuleTitleNode';

interface GraphDisplayProps {
  modules: DialogueModuleRequest[];
  onUpdateModule: (index: number, updatedModule: DialogueModuleRequest) => void;
  onDeleteModule: (index: number) => void;
  newModuleIndex: number | null;
  setNewModuleIndex: (index: number | null) => void;
  addModule: (index: number) => void;
}

const GraphDisplay: React.FC<GraphDisplayProps> = ({
  modules,
  onUpdateModule,
  onDeleteModule,
  newModuleIndex,
  setNewModuleIndex,
  addModule,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  //opening modal for new question added
  useEffect(() => {
    if (newModuleIndex !== null) {
      // Open the modal for the newly added module.
      setSelectedIndex(newModuleIndex);
      setTempModule(modules[newModuleIndex]);
      setDrawerOpen(true);
      // Clear newModuleIndex so that we don't keep re-opening the modal.
      setNewModuleIndex(null);
    }
  }, [newModuleIndex, modules, setNewModuleIndex]);

  //test
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Local state to store temporary edits
  const [tempModule, setTempModule] = useState<DialogueModuleRequest | null>(
    null,
  );

  const handleNodeClick = (_event: React.MouseEvent, node: Node) => {
    // If the node is a group, or if it belongs to a group (its parentId starts with "module-")
    const groupId =
      node.type === 'group'
        ? node.id
        : node.parentId && String(node.parentId).startsWith('module-')
          ? String(node.parentId)
          : null;

    if (groupId) {
      const moduleIndex = parseInt(groupId.split('-')[1]);
      setSelectedIndex(moduleIndex);
      setTempModule(modules[moduleIndex]);
      setDrawerOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDrawerOpen(false);
    setSelectedIndex(null);
    setTempModule(null); // Reset tempModule on cancel
  };

  const handleSave = () => {
    if (selectedIndex !== null && tempModule) {
      onUpdateModule(selectedIndex, tempModule);
    }
    handleCloseDialog();
  };

  const handleAddNewQuestion = () => alert('dodaje nowe pytanie');

  useEffect(() => {
    const newNodes: any[] = [];
    const newEdges: any[] = [];
    // For each module, store an array of node IDs to use as the connection source.
    const moduleConnectionNodeIds: string[][] = [];
    let yOffset = 0;
    let moduleQuestionCount = 0;

    // const filteredModules = modules.filter((module) => !module.disabled); // Filter out disabled modules

    modules.forEach((module, moduleIndex) => {
      const groupId = `module-${moduleIndex}`;

      let questionKeys = Object.keys(module.question_data || {});
      if (module.question_type === 'YESNO') {
        const fixedOrder = ['yes', 'no'];
        questionKeys = questionKeys.sort(
          (a, b) =>
            fixedOrder.indexOf(a.toLowerCase()) -
            fixedOrder.indexOf(b.toLowerCase()),
        );
      } else if (module.question_type === 'LEVEL') {
        const fixedOrder = ['easy', 'medium', 'high'];
        questionKeys = questionKeys.sort(
          (a, b) =>
            fixedOrder.indexOf(a.toLowerCase()) -
            fixedOrder.indexOf(b.toLowerCase()),
        );
      }

      const questionCount = questionKeys.length;
      const hasFollowUp = questionKeys.some(
        (key) => module.question_data[key].followUpQuestion,
      );

      // Layout constants
      const mainNodeWidth = 420;
      const nodeWidth = 150;
      const spacing = 30;
      const minGroupWidth = 500;
      const mainNodeHeight = 50;
      const extraSpacingBelowMain = 50;
      const questionRowHeight = questionCount > 0 ? 150 : 0;
      const followUpRowHeight = hasFollowUp ? 150 : 0;
      const verticalPadding = 30;

      const groupHeight =
        mainNodeHeight +
        extraSpacingBelowMain +
        questionRowHeight +
        followUpRowHeight +
        verticalPadding;

      const totalQuestionWidth =
        questionCount * nodeWidth + (questionCount - 1) * spacing;

      const additionalWidth = questionCount > 2 ? 100 : 0;

      const groupWidth = Math.max(
        minGroupWidth,
        mainNodeWidth + 200,
        totalQuestionWidth + 200,
      );

      // group node
      newNodes.push({
        id: groupId,
        type: 'group',
        data: { label: module.title },
        position: { x: 0, y: yOffset },
        style: {
          width: groupWidth + additionalWidth,
          height:
            (module.type === 'QUESTION' && module.question_type === 'OPEN') ||
            module.disabled
              ? '150px'
              : groupHeight + 40,
          background: COLOR_NEUTRAL_30,
          border: `3px solid ${COLOR_NEUTRAL_50}`,
          borderRadius: 6,
          opacity: module.disabled ? '0.2' : '0.5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        draggable: false,
      });

      //top group connection node
      if (module.type !== 'START') {
        newNodes.push({
          id: `group-connection-top-${moduleIndex}`,
          type: 'groupConnectionNode',
          data: {
            value: 'TOP',
            onClick: handleAddNewQuestion,
          },
          position: { x: (groupWidth + additionalWidth) / 2 - 3, y: 0 },
          style: {
            textAlign: 'center',
            zIndex: 1000,
          },
          draggable: false,
          parentId: groupId,
          extent: 'parent',
        });
      }

      //bottom group connection node
      if (module.type !== 'GOODBYE') {
        newNodes.push({
          id: `group-connection-bot-${moduleIndex}`,
          type: 'groupConnectionNode',
          data: {
            value: 'BOT',
            canAddQuestion: module.type === 'QUESTION',
            onClick: () => addModule(moduleIndex + 1),
          },
          position: {
            x: (groupWidth + additionalWidth) / 2 - 3,
            y: groupHeight + 40,
          },
          style: {
            textAlign: 'center',
            zIndex: 1000,
          },
          draggable: false,
          parentId: groupId,
          extent: 'parent',
        });
      }

      //delete button node
      if (module.type === 'QUESTION' && moduleIndex !== 2) {
        newNodes.push({
          id: `module-delete-${moduleIndex}`,
          type: 'deleteModuleNode',
          data: {
            value: 'BOT',
            canAddQuestion: module.type === 'QUESTION',
            onClick: () => onDeleteModule(moduleIndex),
          },
          position: {
            x: groupWidth + additionalWidth,
            y: 0,
          },
          style: {
            textAlign: 'center',
            zIndex: 1000,
          },
          draggable: false,
          parentId: groupId,
          extent: 'parent',
        });
      }

      //module title node
      newNodes.push({
        id: `group-title-${moduleIndex}`,
        type: 'moduleTitleNode',
        data: {
          label:
            module.type === 'QUESTION'
              ? `Question ${moduleQuestionCount + 1}`
              : module.type,
          isDisabled: module.disabled,
        },
        position: { x: 0, y: yOffset + 0 },
        style: {
          textAlign: 'center',
          fontSize: '12px',
          fontWeight: 'bold',
          color: 'black',
          backgroundColor: 'transparent',
        },

        draggable: false,
      });
      if (module.type === 'QUESTION') {
        moduleQuestionCount++;
      }

      if (module.disabled) {
        moduleConnectionNodeIds.push([`group-connection-bot-1`]);
        yOffset += groupHeight - 80;
        return;
      }

      // Main (bot statement) node
      const mainNodeId = `module-title-${moduleIndex}`;
      newNodes.push({
        id: mainNodeId,
        type: 'inputNode',
        data: {
          label: module.title,
          value: module.bot_statement,
          type: module.type,
          questionType: module.question_type,
        },
        position: {
          x: 90,
          y: 60,
        },
        parentId: groupId,
        extent: 'parent',
        style: {
          textAlign: 'center',
        },
        draggable: false,
      });

      // Use main node as the entry for answer connections.
      const entryNodeId = mainNodeId;

      // We'll store here all node IDs from answers (or their follow-ups)
      const connectionNodes: string[] = [];

      // Create question answer nodes (if any), connecting from the main node.
      const questionY = mainNodeHeight + extraSpacingBelowMain + 50;

      let clientOptions: string[] = [];
      if (module.question_type === 'YESNO') {
        clientOptions = ['Tak, to ja', 'Nie, to nie ja'];
      } else if (module.question_type === 'SCALE') {
        clientOptions = ['np. daje wam ocene 8', 'np. daje wam ocene 9'];
      } else if (module.question_type === 'LEVEL') {
        clientOptions = [
          'np. oceniam was słabo',
          'np. oceniam was średnio ',
          'np. oceniam was wysoko',
        ];
      }

      if (questionCount > 0 && module.question_type !== 'OPEN') {
        const startX = (groupWidth - totalQuestionWidth) / 3;

        questionKeys.forEach((key, qIndex) => {
          const content = module.question_data[key];

          // Skip creating the node if "isOn" is false.
          if (!content.isOn) return;

          const answerNodeId = `q-${moduleIndex}-${qIndex}`;
          const posX = startX + qIndex * (nodeWidth + spacing);

          const clientNodeId = `client-${moduleIndex}-${qIndex}`;
          newNodes.push({
            id: clientNodeId,
            type: 'clientAnswerNode',
            data: { label: clientOptions[qIndex] || '', value: key },
            position: { x: posX + qIndex * 80, y: questionY }, // positioned above the answer node
            parentId: groupId,
            extent: 'parent',
            draggable: false,
          });

          // Create edge from main node to client node.
          newEdges.push({
            id: `edge-${entryNodeId}-${clientNodeId}`,
            source: entryNodeId,
            target: clientNodeId,
            animated: true,
            draggable: false,
            style: { stroke: '#000', strokeDasharray: '5,5' },
          });

          // Create the answer node, using the questionData key as the label.
          newNodes.push({
            id: answerNodeId,
            type: 'answerNode',
            data: {
              label: key,
              value: content.text,
              isFollowUp: content.followUpQuestion,
              followUpText: content.followUpText,
            },
            position: {
              x: qIndex === 0 ? posX : qIndex === 1 ? posX + 70 : posX + 140,
              y: questionY + 70,
            },
            parentId: groupId,
            extent: 'parent',
            style: {
              backgroundColor: '#E6E6E6',
              textAlign: 'center',
            },
            draggable: false,
          });

          // Connect from the main node to this answer node.
          newEdges.push({
            id: `edge-${clientNodeId}-${answerNodeId}`,
            source: clientNodeId,
            target: answerNodeId,
            animated: true,
            draggable: false,
            style: { stroke: '#000', strokeDasharray: '5,5' },
          });

          let followUpOptions: string[] = [];
          if (module.question_type === 'YESNO') {
            followUpOptions = ['Yeah', 'Nope'];
          } else if (module.question_type === 'SCALE') {
            followUpOptions = [
              "I'd rate it 5, honestly.",
              "It's a solid 9 for me",
            ];
          } else if (module.question_type === 'LEVEL') {
            followUpOptions = [
              'It was pretty easy, honestly.',
              'It was average, nothing too crazy',
              'It was a bit difficult, but not imposible.',
            ];
          }

          // If a follow-up exists for this answer, create it and use it as a connection node.
          if (content.followUpQuestion) {
            const followUpNodeId = `followup-${moduleIndex}-${qIndex}`;
            const followUpY = questionY + 130; // 100px below the answer node
            newNodes.push({
              id: followUpNodeId,
              type: 'clientAnswerNode',
              data: { label: followUpOptions[qIndex], value: 'Open' },
              position: { x: qIndex > 0 ? posX + 70 : posX, y: followUpY + 60 },
              parentId: groupId,
              extent: 'parent',
              style: {
                textAlign: 'center',
              },
              draggable: false,
            });

            newEdges.push({
              id: `edge-${answerNodeId}-${followUpNodeId}`,
              source: answerNodeId,
              target: followUpNodeId,
              animated: true,
              draggable: false,
              style: { stroke: '#000', strokeDasharray: '5,5' },
            });

            connectionNodes.push(followUpNodeId);
          } else {
            // No follow-up: use the answer node for connection.
            connectionNodes.push(answerNodeId);
          }
        });
      }

      // If no answer nodes were added, fall back to using the main node.
      if (connectionNodes.length === 0) {
        connectionNodes.push(mainNodeId);
      }

      moduleConnectionNodeIds.push(connectionNodes);

      yOffset += groupHeight + 100;
    });

    // Connect each module in a simple chain.
    // For each module, add an edge from each connection node to the next module's main node.
    for (let i = 0; i < modules.length - 1; i++) {
      const nextModuleMainNodeId = `group-connection-bot-${i}`;
      const sources = moduleConnectionNodeIds[i];

      sources.forEach((sourceId) => {
        newEdges.push({
          id: `edge-connection-${i}-${sourceId}-to-${i + 1}`,
          source: sourceId,
          target: nextModuleMainNodeId,
          animated: true,
          style: { stroke: '#000', strokeDasharray: '5,5' },
          draggable: false,
        });
      });

      newEdges.push({
        id: `edge-group-connection-${i}-to-${i + 1}`,
        source: `group-connection-bot-${i}`,
        target: `group-connection-top-${i + 1}`,
        animated: true,
        style: { stroke: '#000', strokeDasharray: '5,5' },
        draggable: false,
      });
    }

    setNodes(newNodes as any);
    setEdges(newEdges as any);
  }, [modules]);

  const nodeTypes = {
    inputNode: (props: any) => <MainNode {...props} />,
    answerNode: (props: any) => <AnswerNode {...props} />,
    moduleTitleNode: (props: any) => <ModuleTitleNode {...props} />,
    clientAnswerNode: (props: any) => <ClientAnswerNode {...props} />,
    groupConnectionNode: (props: any) => <GroupConnectionNode {...props} />,
    deleteModuleNode: (props: any) => <DeleteModuleNode {...props} />,
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        onNodeClick={handleNodeClick}
        edges={edges}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitView
        style={{ backgroundColor: '#F7F9FB', height: '80vh', width: '100%' }}
      >
        <MiniMap />
        <Controls />
        <Background color="#E6E6E6" />
        <DownloadButton />
      </ReactFlow>
      {tempModule && drawerOpen && selectedIndex !== null && (
        <BotModuleFormDrawer
          drawerOpen={drawerOpen}
          handleCloseDrawer={handleCloseDialog}
          tempModule={tempModule}
          setTempModule={setTempModule}
          selectedIndex={selectedIndex as any}
          handleSave={handleSave}
          deleteModule={() => onDeleteModule(selectedIndex as any)}
        />
      )}
    </>
  );
};

export default GraphDisplay;
