import { styled } from 'styled-components';

export type TypeEnum = 'START' | 'IDENTYFICATION' | 'QUESTION' | 'GOODBYE';

export type QuestionTypeEnum = 'OPEN' | 'LEVEL' | 'YESNO' | 'SCALE';

export interface DialogueModuleRequest {
  /**
   * * `START` - Start
   * * `IDENTYFICATION` - Identyfication
   * * `QUESTION` - Question
   * * `GOODBYE` - Goodbye
   */
  type: TypeEnum;
  disabled: boolean;
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  bot_statement: string;
  bot_statement_shortened: string;
  /**
   * * `OPEN` - Open
   * * `LEVEL` - Level
   * * `YESNO` - Yesno
   * * `SCALE` - Scale
   */
  question_type: QuestionTypeEnum;
  question_data: any;
}

export type TemporaryBotStatusEnum = 'DRAFT' | 'TESTING' | 'DEPLOY';

export interface TemporaryBotRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /** @format uuid */
  strategy: string;
  /**
   * * `DRAFT` - Draft
   * * `TESTING` - Testing
   * * `DEPLOY` - Deploy
   */
  status?: TemporaryBotStatusEnum;
  description?: string | null;
  dialogue_modules: DialogueModuleRequest[];
}

export const initialDialogueModules: DialogueModuleRequest[] = [
  {
    type: 'START',
    title: 'WELCOME',
    bot_statement: 'dzien dobry, tutaj bot lekta',
    bot_statement_shortened: '',
    question_type: 'YESNO',
    question_data: {
      yes: { isOn: true, text: 'test' },
      no: { isOn: true, text: 'test' },
    },
    disabled: false,
  },
  {
    type: 'IDENTYFICATION',
    title: 'IDENTIFICATION',
    bot_statement: 'czy jesteś Jan Kowalski?',
    bot_statement_shortened: '',
    question_type: 'YESNO',
    question_data: {
      yes: {
        isOn: true,
        text: 'super! W takim razie możemy kontynuować rozmowę.',
        followUpQuestion: false,
        followUpText: '',
      },
      no: {
        isOn: true,
        text: 'rozumiem. w takim wypadku nie będę kontynuować rozmowy. dziękuje do widzenia.',
        followUpQuestion: false,
        followUpText: '',
      },
    },
    disabled: false,
  },
  {
    type: 'QUESTION',
    title: 'QUESTION 1',
    bot_statement: 'Please answer yes or no.',
    bot_statement_shortened: '',
    question_type: 'YESNO',
    question_data: {
      yes: {
        isOn: true,
        text: 'Dziekuje za pozytywna odpowiedz!',
        followUpQuestion: false,
        followUpText: 'txt',
      },
      no: {
        isOn: true,
        text: 'Spadaj',
        followUpQuestion: false,
        followUpText: 'txt',
      },
    },
    disabled: false,
  },
  {
    type: 'GOODBYE',
    title: 'GOODBYE',
    bot_statement: 'Czy jestes Jan Kowalski?',
    bot_statement_shortened: '',
    question_type: 'OPEN',
    question_data: {},
    disabled: false,
  },
];

interface TextProps {
  $fontFamily?: 'Lora' | 'SchibstedGrotesk' | 'Roboto';
  $fontSize?: 40 | 34 | 32 | 24 | 22 | 20 | 18 | 16 | 14 | 12 | 10 | 8;
  $grey?: boolean;
  $weight?: number;
  $color?: string;
  $hover?: boolean;
  $pointer?: boolean;
}

export const StyledText = styled.p<TextProps>`
  padding: 0px;
  margin: 0px;
  font-family: ${({ $fontFamily }) => $fontFamily || 'Schibsted Grotesk'},
    ${({ $fontFamily }) =>
      $fontFamily === 'SchibstedGrotesk' ? 'sansserif' : 'serif'};
  font-size: ${({ $fontSize }) => `${$fontSize}px` || '16px'};
  font-weight: ${({ $weight = 400 }) => $weight};

  color: ${({ $color }) => $color || '#292D32'};
  cursor: ${({ $pointer }) => $pointer && 'pointer'};

  &:hover {
    text-decoration: ${({ $hover }) => $hover && 'underline'};
  }

  span {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
