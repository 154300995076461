import { Handle, Position } from '@xyflow/react';
import { StyledText } from '../../sample_data';
import { styled } from 'styled-components';

const ClientAnswerNode: React.FC<any> = ({ data }) => {
  return (
    <ClientAnswerNodeContainer>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '-5%',
          display: 'flex',
          padding: '4px',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          zIndex: 100,
          borderRadius: '6px',
        }}
      >
        <StyledText $fontSize={12}>{data.value.toUpperCase()}</StyledText>
      </div>

      <div>
        <StyledText $fontSize={12} $weight={400} style={{ color: 'white' }}>
          Client answer
        </StyledText>
      </div>
      <StyledText
        $fontSize={12}
        $weight={400}
        style={{ color: 'white', fontStyle: 'italic' }}
      >
        {data.label}
      </StyledText>

      <Handle type="target" position={Position.Top} />

      <Handle type="source" position={Position.Bottom} />
    </ClientAnswerNodeContainer>
  );
};

export default ClientAnswerNode;

const ClientAnswerNodeContainer = styled.div`
  padding: 6px;
  padding-top: 8px;
  border-radius: 6px;
  background-color: #2f2f2f;
  width: 160px;
  min-height: 30px;
  max-height: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  position: relative;
`;
