import { COLOR_NEUTRAL_50, COLOR_NEUTRAL_80 } from '../../../../App.style';
import { StyledText } from '../../sample_data';
import { styled } from 'styled-components';
import { Position, Handle } from '@xyflow/react';

const MainNode: React.FC<any> = ({ data }) => {
  return (
    <MainNodeContainer>
      <StyledText
        $fontSize={10}
        $color={COLOR_NEUTRAL_50}
        style={{ position: 'absolute', top: -14, left: 8 }}
      >
        Bot
      </StyledText>
      <StyledText $fontSize={12} $color={COLOR_NEUTRAL_80}>
        {data.value === ''
          ? 'Nic nie wpisano.'
          : data.value.length > 200
            ? data.value.substring(0, 200) + '...'
            : data.value}
      </StyledText>

      {data.type !== 'GOODBYE' && data.questionType !== 'OPEN' && (
        <Handle type="source" position={Position.Bottom} />
      )}
    </MainNodeContainer>
  );
};

export default MainNode;

export const MainNodeContainer = styled.div`
  padding: 13px;
  border-radius: 9px;
  background-color: white;
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: left;
`;
