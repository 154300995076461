import React, { useEffect, useState } from 'react';
import {
  UsersBarBottomContainer,
  UsersBarTopContainer,
  UsersBarContainer,
  UsersListContainer,
  UsersListSearchContainer,
} from './Users.style';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../common/hooks';
import SearchInput from '../../../common/inputs/SearchInput';
import { H3, H1, Button } from '../../App.style';
import {
  getStrategiesListAsyncThunk,
  selectStrategiesList,
} from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import AddUserModal from './list/AddUserModal';
import UsersTable from './list/UsersTable';
import SuccessPopup from '../../../common/popups/SuccessPopup';
import ErrorPopup from '../../../common/popups/ErrorPopup';
import { SuperSelectDark } from '../../../common/inputs/Inputs.style';
import InviteUserIcon from '../../../resources/icons-new/InviteUserIcon';
import { useAutoClosePopup } from '../../../common/popups/useAutoClosePopup';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../common/modals/LoadingModal';

const UsersPanel = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    dispatch(getStrategiesListAsyncThunk());
  }, []);

  const apiStrategiesList = useAppSelector(selectStrategiesList);

  const [selectedStrategy, setSelectedStrategy] = useState<string>();

  const strategiesOptions = [
    {
      value: 'all',
      inputDisplay: <H3>{t('settings.adminPanel.users.all')}</H3>,
      dropdownDisplay: <H3>{t('settings.adminPanel.users.all')}</H3>,
    },
    ...apiStrategiesList.map((strategy) => ({
      value: strategy.id,
      inputDisplay: <H3>{strategy.name}</H3>,
      dropdownDisplay: <H3>{strategy.name}</H3>,
    })),
  ];

  const handleDetailedClick = (user_id: string) => {
    navigate(`/tenant_panel/users/${user_id}`);
  };

  //add user modal
  const [isAddUserModalVisible, setIsAddUserModalVisible] =
    useState<boolean>(false);
  const openAddUserModal = () => setIsAddUserModalVisible(true);
  const closeAddUserModal = () => setIsAddUserModalVisible(false);

  //invite user popup
  const {
    isVisible: userInvitedPopupVisible,
    showPopup: showInvitePopup,
    closePopup: closeInvitePopup,
  } = useAutoClosePopup();

  const {
    isVisible: successPermissionChangePopupVisible,
    showPopup: showSuccessPermissionsPopup,
    closePopup: closeShowSuccessPopup,
  } = useAutoClosePopup();

  const {
    isVisible: errorPermissionChangePopupVisible,
    showPopup: showErrorPermissionsPopup,
    closePopup: closeShowErrorPopup,
  } = useAutoClosePopup();
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <UsersListContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {successPermissionChangePopupVisible && (
        <SuccessPopup
          text={t('settings.adminPanel.users.popups.permissionsEdited')}
          onClose={closeShowSuccessPopup}
        />
      )}

      {errorPermissionChangePopupVisible && (
        <ErrorPopup
          text={t('settings.adminPanel.users.popups.error')}
          onClose={closeShowErrorPopup}
        />
      )}

      {userInvitedPopupVisible && (
        <SuccessPopup
          text={t('settings.adminPanel.users.popups.userInvited')}
          onClose={closeInvitePopup}
        />
      )}

      <AddUserModal
        isVisible={isAddUserModalVisible}
        closeModal={closeAddUserModal}
        strategiesList={apiStrategiesList}
        showPopup={showInvitePopup}
        setIsLoadingVisible={setIsLoadingVisible}
      />

      <UsersBarContainer>
        <UsersBarTopContainer>
          <H1 $bold>{t('settings.adminPanel.users.title')}</H1>
          <Button $size={'S'} $styleType="NORMAL" onClick={openAddUserModal}>
            {t('settings.adminPanel.users.invite')}
            <InviteUserIcon />
          </Button>
        </UsersBarTopContainer>

        <UsersBarBottomContainer>
          <SuperSelectDark
            style={{
              width: 200,
            }}
            valueOfSelected={selectedStrategy ? selectedStrategy : 'all'}
            options={strategiesOptions}
            onChange={(e) => {
              if (typeof e === 'string') {
                setSelectedStrategy(e !== 'all' ? e : undefined);
              }
            }}
          />
          <UsersListSearchContainer>
            <SearchInput value={search} setValue={setSearch} />
          </UsersListSearchContainer>
        </UsersBarBottomContainer>
      </UsersBarContainer>

      <UsersTable
        search={search}
        handleDetailedClick={handleDetailedClick}
        selectedStrategy={selectedStrategy}
        strategiesList={apiStrategiesList}
        showSuccessPermissionsPopup={showSuccessPermissionsPopup}
        showErrorPermissionsPopup={showErrorPermissionsPopup}
      />
    </UsersListContainer>
  );
};

export default UsersPanel;
