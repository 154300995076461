import { Handle, Position } from '@xyflow/react';
import { COLOR_NEUTRAL_50, COLOR_NEUTRAL_20 } from '../../../../App.style';
import { StyledText } from '../../sample_data';
import { styled } from 'styled-components';

const AnswerNode: React.FC<any> = ({ data }) => {
  const displayValue = () => {
    if (data.value === '' || data.value === undefined) {
      return 'Nic nie wpisano.';
    } else if (data.value && data.value.length > 70) {
      return data.value.substring(0, 70) + '...';
    } else {
      return data.value;
    }
  };
  return (
    <AnswerNodeContainer>
      <StyledText
        $fontSize={10}
        $color={COLOR_NEUTRAL_50}
        style={{ position: 'absolute', top: -14, left: 8 }}
      >
        Bot
      </StyledText>
      <StyledText
        $fontSize={12}
        $weight={400}
        style={{ color: data.value === '' ? 'gray' : 'black' }}
      >
        {displayValue()}
      </StyledText>

      {data.isFollowUp && data.followUpText && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            marginTop: 16,
          }}
        >
          <StyledText $fontSize={10} $color={COLOR_NEUTRAL_20}>
            Follow up:
          </StyledText>
          <StyledText
            $fontSize={12}
            $weight={400}
            style={{ color: data.value === '' ? 'gray' : 'black' }}
          >
            {data.followUpText}
          </StyledText>
        </div>
      )}

      <Handle type="target" position={Position.Top} />

      <Handle type="source" position={Position.Bottom} />
    </AnswerNodeContainer>
  );
};

export default AnswerNode;

export const AnswerNodeContainer = styled.div`
  padding: 13px;
  border-radius: 9px;
  background-color: white;
  width: 205px;
  min-height: 60px;
  max-height: 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  word-break: break-word;
`;
