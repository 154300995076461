import { EuiModalBody, EuiFieldText, EuiToolTip } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../common/modals/Modal';
import { H3, H4 } from '../../../../../../App.style';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import Checkbox from '../../../../../../../common/inputs/Checkbox';
import { InfoIcon } from '../../../../../../../resources/icons-new/InfoIcon';

interface CustomTokenFormModalProps {
  closeModal: () => void;
  selectedCustomToken?: string;
  addCustomToken: (token: string) => void;
  editCustomToken: (oldToken: string, newToken: string) => void;
  cleanTokenName: (tokenName: string) => string;
  customTokensList?: string[];
}

const CustomTokenFormModal: React.FC<CustomTokenFormModalProps> = ({
  closeModal,
  selectedCustomToken,
  addCustomToken,
  editCustomToken,
  cleanTokenName,
  customTokensList,
}) => {
  const { t } = useTranslation();
  const [customTokenName, setCustomTokenName] = useState<string>('');
  const [verbatim, setVerbatim] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCustomToken) {
      if (selectedCustomToken.includes('/VERBATIM')) {
        setVerbatim(true);
      } else {
        setVerbatim(false);
      }

      const cleanedName = cleanTokenName(selectedCustomToken);
      setCustomTokenName(cleanedName);
    } else {
      setVerbatim(false);
      setTokenError(false);
    }
  }, [selectedCustomToken]);

  const createTokenName = (name: string, isVerbatim: boolean): string => {
    let tokenName = `%_CUSTOM_${name}`;
    if (isVerbatim) {
      tokenName += '/VERBATIM';
    }
    return tokenName;
  };

  const [tokenError, setTokenError] = useState<boolean>(false);

  //adding/editing token
  const handleButtonClick = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const newToken = createTokenName(customTokenName, verbatim);

    const isDuplicate = customTokensList?.includes(newToken) ?? false;

    try {
      if (!selectedCustomToken) {
        if (isDuplicate) throw new Error();
        addCustomToken(newToken);
      } else if (selectedCustomToken !== newToken) {
        if (isDuplicate) throw new Error();
        editCustomToken(selectedCustomToken, newToken);
      }

      closeModal();
    } catch (error) {
      setTokenError(true);
    }

    return false;
  };

  const body = (
    <EuiModalBody>
      <FormRow
        label={<H3>Name:</H3>}
        fullWidth
        isInvalid={tokenError}
        error={'Taki token juz istnieje...'}
      >
        <EuiFieldText
          fullWidth
          autoFocus
          value={customTokenName}
          maxLength={64}
          onChange={(e) => {
            const newValue = e.target.value;

            const validPattern = /^[a-zA-Z]+$/;

            if (newValue === '' || validPattern.test(newValue)) {
              setCustomTokenName(newValue.toUpperCase());
              tokenError && setTokenError(false);
            }
          }}
        />
      </FormRow>

      <div
        style={{
          display: 'flex',
          gap: 5,
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          setVerbatim((prev) => !prev);
          tokenError && setTokenError(false);
        }}
      >
        <Checkbox checked={verbatim} readOnly />
        <H4>Token w formie przeliterowanej</H4>
        <EuiToolTip
          position="top"
          content="Bot wypowie ten kawałek tekstu w sposób litera po literze - polecane przy odczycie np. kodów i numerów seryjnych"
        >
          <InfoIcon />
        </EuiToolTip>
      </div>
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={true}
      headerTitle={
        selectedCustomToken === undefined ? 'Create new token' : 'Edit token'
      }
      onCancel={closeModal}
      onConfirm={handleButtonClick}
      buttonText={
        selectedCustomToken === undefined
          ? t('common.create')
          : t('common.saveChanges')
      }
      children={body}
    />
  );
};

export default CustomTokenFormModal;
