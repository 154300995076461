import React from 'react';
import { DialogueModuleRequest, StyledText } from '../sample_data';
import { Button, COLOR_NEUTRAL_80 } from '../../../App.style';
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';
import ModuleForm from './ModuleForm';

interface BotModuleFormDrawerProps {
  drawerOpen: boolean;
  handleCloseDrawer: () => void;
  tempModule: DialogueModuleRequest;
  setTempModule: React.Dispatch<
    React.SetStateAction<DialogueModuleRequest | null>
  >;
  selectedIndex: number;
  handleSave: () => void;
  deleteModule: () => void;
}

const BotModuleFormDrawer: React.FC<BotModuleFormDrawerProps> = ({
  drawerOpen,
  handleCloseDrawer,
  tempModule,
  selectedIndex,
  setTempModule,
  handleSave,
  deleteModule,
}) => {
  const largeFlyoutTitleId = useGeneratedHtmlId({ prefix: 'largeFlyoutTitle' });

  let flyout;
  if (drawerOpen) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={handleCloseDrawer}
        size={'400px'}
        aria-labelledby={largeFlyoutTitleId}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="s">
            <StyledText $weight={500}>Module {tempModule.title}</StyledText>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {tempModule && selectedIndex !== null && (
            <ModuleForm
              module={tempModule}
              onUpdate={(updatedModule) => setTempModule(updatedModule)} // Update temp state
              onDelete={() => {
                deleteModule();
                handleCloseDrawer();
              }}
              isFirstQuestion={tempModule.title === 'QUESTION 1'}
            />
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={handleCloseDrawer}
                flush="left"
                style={{ color: COLOR_NEUTRAL_80 }}
              >
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button onClick={handleSave}>Save</Button>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  return <div>{flyout}</div>;
};

export default BotModuleFormDrawer;
