import { styled } from 'styled-components';
import { StyledText } from '../../sample_data';

const ModuleTitleNode: React.FC<any> = ({ data }) => {
  return (
    <ModuleTitleNodeContainer $isDisabled={data.isDisabled}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <StyledText $fontSize={8}>Module</StyledText>
        <StyledText
          $fontSize={14}
          $weight={700}
          style={{ letterSpacing: '0.535px', textTransform: 'capitalize' }}
        >
          {data.label === 'START' ? 'WELCOME' : data.label}
        </StyledText>
      </div>

      {data.isDisabled && (
        <StyledText
          $fontSize={14}
          $weight={700}
          style={{ letterSpacing: '0.535px', textTransform: 'capitalize' }}
        >
          MODUŁ NIEAKTYWNY
        </StyledText>
      )}
    </ModuleTitleNodeContainer>
  );
};

export default ModuleTitleNode;

export const ModuleTitleNodeContainer = styled.div<{ $isDisabled: boolean }>`
  padding: 13px;
  border-radius: 9px;
  background-color: transparent;
  width: 590px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
`;
