import {
  EuiFieldText,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
} from '@elastic/eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Button, H2, H3 } from '../../../App.style';
import {
  CalendarConfiguration,
  CallCenterNonWorkingDaysConfiguration,
  CampaignNonWorkingDaysConfiguration,
  ProcessCreateRequest,
} from '../../../../generated/tenants/Api';
import {
  getCalendarsListAsyncThunk,
  selectCalendarsList,
} from '../../presets/calendars/api/calendarSlice';
import {
  getBotDaysOffListAsyncThunk,
  selectBotDaysOffList,
} from '../../presets/days_off/bot/api/botDaysOffSlice';
import BotDaysOffCreate from '../../presets/days_off/bot/BotDaysOffCreate';
import {
  getCallCenterDaysOffListAsyncThunk,
  selectCallCenterDaysOffList,
} from '../../presets/days_off/callCentre/api/callCentreDaysOffSlice';
import CallCenterCreate from '../../presets/days_off/callCentre/CallCenterCreate';
import {
  DayOffRow,
  FormContainer,
  FormRow,
  PresetsBorder,
} from './ProcessForm.style';
import WorkingHoursPreview from '../../presets/calendars/workingHours/preview/WorkingHoursPreview';
import CalendarCreate from '../../presets/calendars/CalendarCreate';
import { SuperSelectDark } from '../../../../common/inputs/Inputs.style';
import { useTranslation } from 'react-i18next';

interface CalendarFormProps {
  strategy: string;
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  edit?: boolean;
}

const CalendarForm: React.FC<CalendarFormProps> = ({
  strategy,
  process,
  setProcess,
  edit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCalendarsListAsyncThunk(strategy));
    dispatch(getBotDaysOffListAsyncThunk(strategy));
    dispatch(getCallCenterDaysOffListAsyncThunk(strategy));
  }, [strategy]);

  const apiCalendarsList = useAppSelector(selectCalendarsList);
  const apiBotDaysOffList = useAppSelector(selectBotDaysOffList);
  const apiCallCenterDaysOffList = useAppSelector(selectCallCenterDaysOffList);

  //calendar
  const [calendarPreset, setCalendarPreset] = useState<CalendarConfiguration>();

  const [newCalendarCreated, setNewCalendarCreated] = useState<boolean>(false);
  const handleNewCalendarChange = () => {
    setNewCalendarCreated((prev) => !prev);
  };

  const findCalendar = (calendarId: string) => {
    return apiCalendarsList.find((calendar) => calendar.id === calendarId);
  };

  useEffect(() => {
    if (newCalendarCreated) {
      setCalendarPreset(apiCalendarsList[apiCalendarsList.length - 1]);
      handleNewCalendarChange();
      return;
    } else if (process.calendar_configuration !== '') {
      const selected = findCalendar(process.calendar_configuration);
      if (selected) setCalendarPreset(selected);
    }
  }, [apiCalendarsList]);

  const calendarsOptions = [
    ...apiCalendarsList.map((calendar) => {
      return {
        value: calendar.id,
        inputDisplay: calendar.name,
        dropdownDisplay: <H3>{calendar.name}</H3>,
      };
    }),
  ];

  //call center days off
  const [callCenterDaysOffPreset, setCallCenterDaysOffPreset] =
    useState<CallCenterNonWorkingDaysConfiguration>();

  const findCallCenter = (ccId: string) => {
    return apiCallCenterDaysOffList.find((cc) => cc.id === ccId);
  };

  const [newCallCenterDaysOffCreated, setNewCallCenterDaysOffCreated] =
    useState<boolean>(false);
  const handleNewCallCenterDaysOffChange = () => {
    setNewCallCenterDaysOffCreated((prev) => !prev);
  };

  useEffect(() => {
    if (newCallCenterDaysOffCreated) {
      setCallCenterDaysOffPreset(
        apiCallCenterDaysOffList[apiCallCenterDaysOffList.length - 1],
      );
      handleNewCallCenterDaysOffChange();
      return;
    } else if (process.call_center_non_working_days_configuration !== '') {
      const selected = findCallCenter(
        process.call_center_non_working_days_configuration,
      );

      if (selected) setCallCenterDaysOffPreset(selected);
    }
  }, [apiCallCenterDaysOffList]);

  const callCenterDaysOffoptions = [
    ...apiCallCenterDaysOffList.map((cc) => {
      return {
        value: cc.id,
        inputDisplay: cc.name,
        dropdownDisplay: <H3>{cc.name}</H3>,
      };
    }),
  ];

  //bot days off
  const [botDaysOffPreset, setBotDaysOffPreset] =
    useState<CampaignNonWorkingDaysConfiguration>();

  const findBotDaysOff = (botId: string) => {
    return apiBotDaysOffList.find((bot) => bot.id === botId);
  };

  const [newBotDaysOffCreated, setNewBotDaysOffCreated] =
    useState<boolean>(false);
  const handleNewBotDaysOffChange = () => {
    setNewBotDaysOffCreated((prev) => !prev);
  };

  useEffect(() => {
    if (newBotDaysOffCreated) {
      setBotDaysOffPreset(apiBotDaysOffList[apiBotDaysOffList.length - 1]);
      handleNewBotDaysOffChange();
      return;
    } else if (process.campaign_non_working_days_configuration !== '') {
      const selected = findBotDaysOff(
        process.campaign_non_working_days_configuration,
      );
      if (selected) setBotDaysOffPreset(selected);
    }
  }, [apiBotDaysOffList]);

  const botDaysOffOptions = [
    ...apiBotDaysOffList.map((bot) => {
      return {
        value: bot.id,
        inputDisplay: bot.name,
        dropdownDisplay: <H3>{bot.name}</H3>,
      };
    }),
  ];

  useEffect(() => {
    if (
      calendarPreset &&
      process.calendar_configuration !== calendarPreset.id
    ) {
      setProcess({ ...process, calendar_configuration: calendarPreset.id });
    }

    if (
      callCenterDaysOffPreset &&
      process.call_center_non_working_days_configuration !==
        callCenterDaysOffPreset.id
    ) {
      setProcess({
        ...process,
        call_center_non_working_days_configuration: callCenterDaysOffPreset.id,
      });
    }

    if (
      botDaysOffPreset &&
      process.campaign_non_working_days_configuration !== botDaysOffPreset.id
    ) {
      setProcess({
        ...process,
        campaign_non_working_days_configuration: botDaysOffPreset.id,
      });
    }
  }, [calendarPreset, callCenterDaysOffPreset, botDaysOffPreset]);

  //calendar modify
  const [isModifyWorkingHoursVisible, setIsModifyWorkingHoursVisible] =
    useState<boolean>(false);
  const closeModifyWorkingHoursModal = () =>
    setIsModifyWorkingHoursVisible(false);
  const showModifyWorkingHoursModal = () =>
    setIsModifyWorkingHoursVisible(true);

  let modifyWorkingHoursModal;
  if (isModifyWorkingHoursVisible && calendarPreset) {
    modifyWorkingHoursModal = (
      <EuiModal
        onClose={closeModifyWorkingHoursModal}
        style={{ minWidth: 1100, height: '80vh' }}
      >
        <EuiModalHeader style={{ paddingLeft: 340 }}>
          <H2>{t('processes.form.calendar.newCalendar')}</H2>
        </EuiModalHeader>

        <EuiModalBody>
          <CalendarCreate
            calendar={calendarPreset}
            strategy={strategy}
            backToList={closeModifyWorkingHoursModal}
            handleNewCalendarChange={handleNewCalendarChange}
            formButtonDown
          />
        </EuiModalBody>
      </EuiModal>
    );
  }

  //cc days off  modify
  const [isModifyCCDaysOffPresetVisible, setIsModifyCCDaysOffVisible] =
    useState<boolean>(false);

  const closeModifyCCDaysOffModal = () => setIsModifyCCDaysOffVisible(false);
  const showModifyCCDaysOffModal = () => setIsModifyCCDaysOffVisible(true);

  let modifyCcModal;

  if (isModifyCCDaysOffPresetVisible && callCenterDaysOffPreset) {
    modifyCcModal = (
      <EuiModal onClose={closeModifyCCDaysOffModal} style={{ width: 600 }}>
        <EuiModalHeader style={{ paddingLeft: 90 }}>
          <H2>{t('processes.form.calendar.newPresetCC')}</H2>
        </EuiModalHeader>

        <EuiModalBody>
          <CallCenterCreate
            ccDaysOff={callCenterDaysOffPreset}
            strategy={strategy}
            backToList={closeModifyCCDaysOffModal}
            handleNewCallCenterDaysOffChange={handleNewCallCenterDaysOffChange}
            formButtonDown
          />
        </EuiModalBody>
      </EuiModal>
    );
  }

  //bot days off modify
  const [isModifyBotDaysOffPresetVisible, setIsModifyBotDaysOffPresetVisible] =
    useState<boolean>(false);
  const closeModifyBotDaysOffModal = () =>
    setIsModifyBotDaysOffPresetVisible(false);
  const showModifyBotDaysOffModal = () =>
    setIsModifyBotDaysOffPresetVisible(true);

  let modifyBotModal;

  if (isModifyBotDaysOffPresetVisible && botDaysOffPreset) {
    modifyCcModal = (
      <EuiModal onClose={closeModifyBotDaysOffModal} style={{ width: 600 }}>
        <EuiModalHeader style={{ paddingLeft: 90 }}>
          <H2>{t('processes.form.calendar.newPresetBot')}</H2>
        </EuiModalHeader>

        <EuiModalBody>
          <BotDaysOffCreate
            botDaysOff={botDaysOffPreset}
            strategy={strategy}
            backToList={closeModifyBotDaysOffModal}
            handleNewBotDaysOffChange={handleNewBotDaysOffChange}
            formButtonDown
          />
        </EuiModalBody>
      </EuiModal>
    );
  }

  return (
    <FormContainer $processForm>
      {modifyCcModal}
      {modifyBotModal}
      {modifyWorkingHoursModal}

      <FormRow label={<H3>{t('processes.form.calendar.selectCalendar')}</H3>}>
        <SuperSelectDark
          disabled={!edit}
          options={calendarsOptions}
          placeholder={t('common.select')}
          valueOfSelected={
            calendarPreset ? calendarPreset.id : 'PLACEHOLDER_VALUE'
          }
          onChange={(e) => {
            const selectedPreset = findCalendar(e as string);
            if (e !== '') setCalendarPreset(selectedPreset);
          }}
        />
      </FormRow>

      {calendarPreset && (
        <PresetsBorder>
          <FormRow label={<H3>{t('processes.form.calendar.timezone')}</H3>}>
            <EuiFieldText value={calendarPreset.timezone} readOnly={true} />
          </FormRow>

          <WorkingHoursPreview calendarConfiguration={calendarPreset} />

          {edit && (
            <Button
              $styleType="NORMAL"
              $size="S"
              type="button"
              onClick={showModifyWorkingHoursModal}
            >
              {t('common.modify')}
            </Button>
          )}
        </PresetsBorder>
      )}

      <FormRow
        style={{ marginTop: 40 }}
        label={<H3>{t('processes.form.calendar.daysOffCC')}</H3>}
      >
        <SuperSelectDark
          disabled={!edit}
          options={callCenterDaysOffoptions}
          placeholder={t('common.select')}
          valueOfSelected={
            callCenterDaysOffPreset ? callCenterDaysOffPreset.id : ''
          }
          onChange={(e) => {
            const selectedPreset = findCallCenter(e as string);
            if (e !== '') setCallCenterDaysOffPreset(selectedPreset);
          }}
        />
      </FormRow>

      {callCenterDaysOffPreset && (
        <PresetsBorder>
          {callCenterDaysOffPreset.holidays?.length === 0 ? (
            <DayOffRow>{t('processes.form.calendar.nothing')}</DayOffRow>
          ) : (
            <>
              {callCenterDaysOffPreset.holidays?.map((holiday, index) => {
                return (
                  <DayOffRow key={`call-center-days-off-${index}`}>
                    <H3>{moment(holiday).format('D MMMM YYYY')}</H3>
                  </DayOffRow>
                );
              })}
            </>
          )}

          {edit && (
            <Button
              $styleType="NORMAL"
              $size="S"
              type="button"
              onClick={showModifyCCDaysOffModal}
            >
              {t('common.modify')}
            </Button>
          )}
        </PresetsBorder>
      )}

      <FormRow
        style={{ marginTop: 40 }}
        label={<H3>{t('processes.form.calendar.daysOffBot')}</H3>}
      >
        <SuperSelectDark
          disabled={!edit}
          options={botDaysOffOptions}
          placeholder={t('common.select')}
          valueOfSelected={botDaysOffPreset ? botDaysOffPreset.id : ''}
          onChange={(e) => {
            const selectedPreset = findBotDaysOff(e as string);
            if (e !== '') setBotDaysOffPreset(selectedPreset);
          }}
        />
      </FormRow>

      {botDaysOffPreset && (
        <PresetsBorder>
          {botDaysOffPreset.holidays?.length === 0 ? (
            <DayOffRow>{t('processes.form.calendar.nothing')}</DayOffRow>
          ) : (
            <>
              {botDaysOffPreset.holidays?.map((holiday, index) => {
                return (
                  <DayOffRow key={`bot-days-off-${index}`}>
                    <H3>{moment(holiday).format('D MMMM YYYY')}</H3>
                  </DayOffRow>
                );
              })}
            </>
          )}

          {edit && (
            <Button
              $styleType="NORMAL"
              $size="S"
              type="button"
              onClick={showModifyBotDaysOffModal}
            >
              {t('common.modify')}
            </Button>
          )}
        </PresetsBorder>
      )}
    </FormContainer>
  );
};

export default CalendarForm;
