import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const PresetsIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M7 3V11H3L3 3H7ZM3 2C2.44772 2 2 2.44772 2 3V11C2 11.5523 2.44772 12 3 12H7C7.55228 12 8 11.5523 8 11V3C8 2.44772 7.55228 2 7 2H3Z" />
    <path d="M16 4V9H11V4H16ZM11 3C10.4477 3 10 3.44772 10 4V9C10 9.55228 10.4477 10 11 10H16C16.5523 10 17 9.55228 17 9V4C17 3.44772 16.5523 3 16 3H11Z" />
    <path d="M7 15V17H5V15H7ZM5 14C4.44772 14 4 14.4477 4 15V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 7.55228 14 7 14H5Z" />
    <path d="M17 13V15H11V13H17ZM11 12C10.4477 12 10 12.4477 10 13V15C10 15.5523 10.4477 16 11 16H17C17.5523 16 18 15.5523 18 15V13C18 12.4477 17.5523 12 17 12H11Z" />
  </StyledSvg>
);
